import api from "../api";
import {useLoaderData} from "react-router-dom";
import {useEffect, useState} from "react";
import {Divider, Grid, Space} from "@arco-design/web-react";
import {IconApps, IconMenu} from "@arco-design/web-react/icon";
import {Gallery} from "react-grid-gallery";
import {Image} from '@arco-design/web-react';

const Row = Grid.Row;
const Col = Grid.Col;

export async function loader({params}) {
    try {
        const info = await api.photo.get({id: params.photoId});
        return {info: info};
    } catch (e) {
        return {}
    }
}

export function PhotoPage() {
    const {info} = useLoaderData();
    const [mode, setMode] = useState(1);


    // 画廊
    const [index, setIndex] = useState(-1);
    const handleClick = (index, item) => {
        setIndex(index);
        setVisible(true);
    };
    const [visible, setVisible] = useState(false);


    useEffect(() => {
        const mainBody = document.getElementById("main-body");
        mainBody.scrollTop = 0;
    }, []);

    if (!info) {
        return <div></div>
    }

    return <div className={"max-w-content"} style={{paddingTop: 0}}>
        <div style={{
            backgroundImage: `url(${info.photos[0].url + '?x-oss-process=image/resize,m_lfit,w_1920/format,webp'})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            backgroundPosition: '38% 10%',
            height: '600px',
            marginLeft: '20px',
            marginRight: '20px',
        }}>
        </div>
        <div className={"post-header"}>
            <div className={"post-header__date"}>
                {info.date}
            </div>
            <div className={"post-header__title"}>
                <Row style={{alignItems: "center"}}>
                    <Col flex='auto'>
                        <h1>
                            {info.title}
                        </h1>
                    </Col>
                    <Col flex={"20px"}>
                        <Divider type='vertical'/>
                    </Col>
                    <Col flex={"60px"}>
                        <div style={{textAlign: "right", marginTop: 8}}>
                            <Space size={10}>
                                <IconApps onClick={() => {
                                    setMode(0)
                                }}
                                          style={{
                                              fontSize: 20,
                                              color: mode === 0 ? '#333' : '#999',
                                              display: "block",
                                              cursor: "pointer"
                                          }}/>
                                <IconMenu onClick={() => {
                                    setMode(1)
                                }}
                                          style={{
                                              fontSize: 20,
                                              color: mode === 1 ? '#333' : '#999',
                                              display: "block",
                                              cursor: "pointer"
                                          }}/>
                            </Space>
                        </div>
                    </Col>
                </Row>
            </div>
            <div className={"post-header__categories"}>
                {info.category.name}
            </div>
            {info.content && <div className={"block-container"} style={{paddingLeft: 0, color: '#666'}}>
                {info.content}
            </div>}
        </div>
        <div className={"photo__photo-container"}>
            {mode === 1 &&
                info.photos.map(n => {
                    if (n.fileType === 'image') {
                        return <img key={n.url} className={"photo__photo"} style={{maxWidth: n.width + 'px'}}
                                    src={n.url + '?x-oss-process=image/resize,m_lfit,w_1920/format,webp'} alt={""}/>
                    } else {
                        return <video src={n.url} key={n.url} className={"photo__photo"}
                                      style={{maxWidth: n.width + 'px'}}
                                      controls></video>
                    }
                })
            }

            {mode === 0 && <Gallery
                rowHeight={300}
                images={info.photos.map(n => {
                    return {
                        original: n.fileType === 'video' ? n.cover : (n.url + '?x-oss-process=image/resize,m_lfit,w_1920/format,webp'),
                        src: n.fileType === 'video' ? n.cover : (n.url + '?x-oss-process=image/resize,m_lfit,w_400/format,webp'),
                        width: n.width,
                        height: n.height,
                        caption: ""
                    }
                })}
                onClick={handleClick}
                enableImageSelection={false}
            />}
            <Image.PreviewGroup current={index} onChange={(index) => {
                setIndex(index);
            }} srcList={info.photos.map(n => {
                let url = n.url + '?x-oss-process=image/resize,m_lfit,w_1920/format,webp'
                if (n.fileType === 'video') {
                    url = n.cover
                }
                return url
            })} visible={visible} onVisibleChange={setVisible}/>
        </div>
    </div>
}
