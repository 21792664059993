import React, {useState, useEffect} from 'react';

const InfiniteScroll = ({loadMore, hasMore, loader, children}) => {
    const [isLoading, setIsLoading] = useState(false);

    const handleScroll = () => {
        const mainBody = document.getElementById("main-body");
        if (!isLoading && hasMore.current &&
            (mainBody.clientHeight + mainBody.scrollTop) >= mainBody.scrollHeight) {
            setIsLoading(true);
            loadMore().then(() => setIsLoading(false));
        }
    };

    useEffect(() => {
        const mainBody = document.getElementById("main-body");
        mainBody.addEventListener('scroll', handleScroll);
        return () => mainBody.removeEventListener('scroll', handleScroll);
    }, []);

    return (
        <div>
            {children}
        </div>
    );
};

export default InfiniteScroll;
