import api from "../api";
import {useLoaderData} from "react-router-dom";

export async function loader({params}) {
    try {
        const coverInfo = await api.cover.get({shopId: params.shopId});
        return {coverInfo: coverInfo};
    } catch (e) {
        return {}
    }
}

export function CoverPage() {
    const {coverInfo} = useLoaderData();

    return (
        <div>
            <div className={"photo__photo-container"}>
                {
                    coverInfo && coverInfo.resources.map(n => {
                        if (n.fileType === 'image') {
                            return <img key={n.url} className={"photo__photo"} style={{maxWidth: n.width + 'px'}}
                                        src={n.url + '?x-oss-process=image/resize,m_lfit,w_1920/format,webp'} alt={""}/>
                        } else {
                            return <video src={n.url} key={n.url} className={"photo__photo"}
                                          style={{maxWidth: n.width + 'px'}}
                                          controls></video>
                        }
                    })
                }
            </div>
        </div>
    );
}
