import {useNavigate, useParams} from "react-router-dom";
import {useEffect, useRef, useState} from "react";
import api from "../api/index";
import {Empty, Grid, Spin} from "@arco-design/web-react";
import InfiniteScroll from "../include/InfiniteScroll";

const Row = Grid.Row;
const Col = Grid.Col;

export function CategoryPage() {
    const params = useParams();
    const [pageSize, setPageSize] = useState(10)
    const [loading, setLoading] = useState(false)
    const pageRef = useRef(1)
    const listRef = useRef([])
    const hasMoreRef = useRef(true)
    const navigate = useNavigate();

    function GetList() {
        setLoading(true)
        return api.photo.list({
            shopId: params.shopId,
            photoCategoryId: params.categoryId,
            page: pageRef.current,
            pageSize
        }).then(res => {
            setLoading(false)
            if (pageRef.current === 1) {
                listRef.current = res
            } else {
                listRef.current = [...listRef.current, ...res]
            }
            if (res.length < pageSize) {
                hasMoreRef.current = false
            }
        }).catch(e => {
            console.log(e)
        })
    }

    useEffect(() => {
        const mainBody = document.getElementById("main-body");
        mainBody.scrollTop = 0;

        hasMoreRef.current = true
        pageRef.current = 1
        GetList()
    }, [params.categoryId]);


    function LeftImage({data}) {
        let url = data.photos[0].url + '?x-oss-process=image/resize,m_lfit,w_1920/format,webp'
        if (data.photos[0].fileType === 'video') {
            url = data.photos[0].cover
        }

        return <div
            style={{
                backgroundImage: 'url(' + url + ')',
                backgroundPosition: '75% 50%',
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
                paddingBottom: '100%'
            }}></div>
    }

    function LeftDesc({data}) {
        return <div className={"post__text text-center post__text--left text-md-left"}>
            <div className={"post__blog-category"}>{data.category.name}</div>
            <h2 className={"post__title"}>
                {data.title}
            </h2>
            <hr className={"post__divider"}/>
            <p className={"post__date"}>
                {data.date}
            </p>
        </div>
    }

    function RightImage({data}) {
        let url = data.photos[0].url + '?x-oss-process=image/resize,m_lfit,w_1920/format,webp'
        if (data.photos[0].fileType === 'video') {
            url = data.photos[0].cover
        }

        return <div
            style={{
                backgroundImage: 'url(' + url + ')',
                backgroundPosition: '75% 50%',
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
                paddingBottom: '100%'
            }}></div>
    }

    function RightDesc({data}) {
        return <div className={"post__text text-center post__text--right text-md-right"}>
            <div className={"post__blog-category"}>{data.category.name}</div>
            <h2 className={"post__title"}>
                {data.title}
            </h2>
            <hr className={"post__divider"}/>
            <p className={"post__date"}>
                {data.date}
            </p>
        </div>
    }

    return <Spin loading={loading}>
        {listRef.current.length === 0 && <div style={{marginTop: 40}}>
            <Empty/>
        </div>}
        <div>
            <InfiniteScroll hasMore={hasMoreRef} loadMore={() => {
                pageRef.current = pageRef.current + 1
                return GetList()
            }}>
                <div className={"max-w-content"}>
                    {
                        listRef.current.map((n, idx) => {
                            return <div key={n.id} className={"post"} style={{margin: 20}} onClick={() => {
                                navigate(`/photo/${params.shopId}/${n.id}`)
                            }}>
                                <Row gutter={{md: 8, lg: 24, xl: 32}} style={{alignItems: "center"}}>
                                    <Col flex={6} style={{zIndex: idx % 2 === 0 ? 1 : 2}}>
                                        {idx % 2 === 0 ? <LeftImage data={n}/> : <RightDesc data={n}/>}
                                    </Col>
                                    <Col flex={6} style={{zIndex: idx % 2 === 0 ? 2 : 1}}>
                                        {idx % 2 === 0 ? <LeftDesc data={n}/> : <RightImage data={n}/>}
                                    </Col>
                                </Row>
                            </div>
                        })
                    }
                </div>
            </InfiniteScroll>
        </div>
    </Spin>
}
