import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import {createBrowserRouter, RouterProvider} from "react-router-dom";
import {HomePage, loader} from "./pages/HomePage";
import "@arco-themes/react-photography/index.less";
import {CoverPage, loader as CoverLoader} from "./pages/CoverPage";
import {CategoryPage} from "./pages/CategoryPage";
import {PhotoPage, loader as PhotoPageLoader} from "./pages/PhotoPage";
import {ErrorPage} from "./pages/ErrorPage";

const router = createBrowserRouter([
    {
        path: "/",
        loader: loader,
        element: <HomePage/>,
        errorElement: <ErrorPage />,
        children: [
            {
                path: "s/:shopId",
                element: <CoverPage/>,
                loader: CoverLoader,
            },
            {
                path: "category/:shopId/:categoryId",
                element: <CategoryPage/>,
            },
            {
                path: "photo/:shopId/:photoId",
                element: <PhotoPage/>,
                loader: PhotoPageLoader
            },
            {
                path: "*",
                element: <ErrorPage/>
            }
        ]
    }
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <RouterProvider router={router}/>
);
