import {instance} from "../lib/request";

function get(options) {
    return instance.get('/api/web/cover/get',{
        params: options,
    });
}


export default {
    get
}
