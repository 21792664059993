import axios from "axios";

const instance = axios.create({
    // baseURL: 'http://127.0.0.1:8080'
    baseURL: 'https://api.picaart.cn'
});


instance.interceptors.response.use(function (response) {
    // 2xx 范围内的状态码都会触发该函数。
    // 对响应数据做点什么
    const data = response.data
    if (data.state) {
        return data.data
    } else {
        return Promise.reject({message: data.msg});
    }
}, function (error) {
    // 超出 2xx 范围的状态码都会触发该函数。
    // 对响应错误做点什么
    return Promise.reject(error);
});

export {instance}
